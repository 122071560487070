import "./App.css";

import { ChakraProvider, Box } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./routes/appRouter";
import { RecoilRoot } from "recoil";
import ErrorBoundary from "./components/errorBoundary";
import chakraTheme from "./lib/chakra/theme";

function App() {
  const queryClient = new QueryClient();

  return (
    <ErrorBoundary>
      <RecoilRoot>
        <HelmetProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ChakraProvider theme={chakraTheme}>
                <Box w={"100vw"} overflowX="scroll" minH={"100vh"}>
                  <AppRouter />
                  <Toaster
                    position="top-center"
                    containerStyle={{
                      top: "60px",
                    }}
                    toastOptions={{
                      style: {
                        fontSize: "16px",
                        border: "1px solid #E7E8EB",
                        borderRadius: "8px",
                        textAlign: "center",
                        fontWeight: 500,
                        overflow: "hidden",
                        background: "#0F172A",
                        color: "#fff",
                        paddingInline: "20px",
                      },
                    }}
                  />
                </Box>
              </ChakraProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default App;
