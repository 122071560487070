import { useUserType } from "@/hooks/useUserType";
import { ROUTES } from "@/utils/constants";
import { clearStorage } from "@/utils/helpers/auth";
import {
  Box,
  Center,
  HStack,
  Image,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import {
  CircleUser,
  LayoutDashboard,
  LogOut,
  RefreshCw,
  Users,
} from "lucide-react";
import { BarChart3 } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// eslint-disable-next-line
// @ts-ignore
import { SlideOut } from "../animate/slideOut";

const navOptions = [
  {
    path: ROUTES.dashboard,
    title: "Home",
    icon: LayoutDashboard,
  },
  {
    path: ROUTES.children,
    title: "Children",
    icon: Users,
  },
  {
    path: ROUTES.subscription,
    title: "Subscription",
    icon: RefreshCw,
  },

  {
    path: ROUTES.performance,
    title: "Leader board",
    icon: BarChart3,
  },
  {
    path: ROUTES.account,
    title: "Account",
    icon: CircleUser,
  },
  {
    name: "logout",
    title: "Logout",
    icon: LogOut,
  },
];

const POrgSidebar = ({
  isMobile,
  onClose,
}: {
  isMobile?: boolean;
  onClose?: () => void;
}) => {
  const { theme } = useUserType();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <VStack
      h={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      pl={!isMobile ? 7 : 0}
      bg={isMobile ? theme.bg : "transparent"}
    >
      <Center mb={12}>
        <Image
          alt="logo"
          src={"/logo.svg"}
          width={"150px"}
          height={"69px"}
          objectFit={"contain"}
        />
      </Center>

      <VStack
        flex={1}
        as="nav"
        alignItems={"flex-start"}
        spacing={3}
        px={isMobile ? 5 : 1}
        pb={14}
      >
        <AnimatePresence>
          {navOptions?.map((route, idx) => {
            const Icon = route.icon;
            const isLogout = route.name === "logout";

            return (
              <SlideOut
                key={idx}
                className={isLogout ? "w-full mt-auto" : "w-full"}
                x={-30}
                delay={(idx + 1) * 0.25}
                duration={(idx + 1) * 0.1}
              >
                {!isLogout ? (
                  <ChakraLink
                    as={Link}
                    to={route.path}
                    onClick={() => {
                      if (isMobile && onClose) {
                        onClose();
                      }
                    }}
                    display={"flex"}
                    gap={2}
                    alignItems={"center"}
                    width={"100%"}
                    px={6}
                    py={3}
                    border={"4px solid"}
                    borderColor={
                      route.path !== pathname ? theme.border100 : theme.primary
                    }
                    borderBottomColor={
                      route.path === pathname
                        ? theme.main["900"]
                        : theme.border100
                    }
                    borderRadius={"24px"}
                    color={route.path === pathname ? "white" : theme.text}
                    role={"group"}
                    bg={
                      route.path === pathname
                        ? theme.main["500"]
                        : "transparent"
                    }
                    _hover={{
                      textDecoration: "none",
                      borderColor:
                        route.path !== pathname ? theme.text100 : undefined,
                    }}
                    className="!transition-all !duration-100"
                  >
                    <Box
                      width={isMobile ? "38px" : "22px"}
                      color={route.path === pathname ? "white" : theme.text}
                      //   _groupHover={{ color: "white" }}
                      className="transition-all duration-150"
                    >
                      <Icon
                        width={isMobile ? 26 : 20}
                        height={isMobile ? 26 : 20}
                      />
                    </Box>
                    <Text
                      as="span"
                      textStyle={"medium"}
                      color={route.path === pathname ? "white" : theme.text}
                      //   _groupHover={{ color: "white" }}

                      className="transition-all duration-150"
                    >
                      {route.title}
                    </Text>
                  </ChakraLink>
                ) : (
                  <HStack
                    key={idx}
                    mt={"auto"}
                    spacing={3}
                    role={"button"}
                    pl={6}
                    onClick={() => {
                      clearStorage();
                      //        window.location.href = "/";
                      navigate("/login");
                    }}
                  >
                    <Box color={theme.text}>
                      <Icon size={isMobile ? 28 : 20} />
                    </Box>

                    <Text
                      color={theme.text}
                      fontWeight={700}
                      textStyle={"medium"}
                    >
                      {route.title}
                    </Text>
                  </HStack>
                )}
              </SlideOut>
            );
          })}
        </AnimatePresence>
      </VStack>
    </VStack>
  );
};

export default POrgSidebar;
