export const GLOBAL_KEYS = {
  token: "gwa_token",
  USER_TYPE: "gwa_user_type",
  ORG_UUID: "gwa_defaukt_org_uuid",
  ONBOARDING_COMPLETE: "gwa_onboarding_complete",
  REDIRECT_ROUTE: "gwa_redirect_route",
  PAYMENT_GATEWAY: "gwa_payment_gateway",
};

export const APP_VALUES = {
  par_website:
    import.meta.env.VITE_PAR_WEBSITE_LINK || "https://proudafricanroots.com",
};

export const LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const THEMES = {
  milk: "#FFFDF5",
  text: {
    100: "#0D0E1236",
    200: "#0D0E1266",
    300: "#0D0E1286",
    400: "#0D0E1299",
    500: "#0D0E12",
  },
  placeholder: "#0D0E1266",
  brown: {
    50: "hsl(26, 100%, 79%)",
    100: "hsl(26, 100%, 69%)",
    200: "hsl(26, 100%, 59%)",
    300: "hsl(26, 100%, 49%)",
    400: "hsl(26, 100%, 39%)",
    500: "hsl(26, 100%, 29%)",
    600: "hsl(26, 100%, 26%)",
    700: "hsl(26, 100%, 23%)",
    800: "hsl(26, 100%, 20%)",
    900: "hsl(26, 100%, 18%)",
  },
  stroke: {
    300: " #E4E4EC",
  },
  org: {
    primary: "#189DDD",
    text: "#1483B8B8",
    text100: "#1483B840",
    bg: "#F5FCFF",
    bg100: "#189DDD05",
    border: "#bbd4e0",
    border100: "#1483B814",
    main: {
      50: "hsl(199, 80%, 10%)",
      100: "hsl(199, 80%, 19%)",
      200: "hsl(199, 80%, 28%)",
      300: "hsl(199, 80%, 34%)",
      400: "hsl(199, 80%, 40%)",
      500: "#189DDD",
      600: "hsl(199, 80%, 58%)",
      700: "hsl(199, 80%, 68%)",
      800: "hsl(199, 80%, 78%)",
      900: "#1483B8",
    },
  },
  parent: {
    primary: "#01A262",
    text: "#01A262B8",
    text100: "#01A26240",
    bg: "#01A26214",
    bg100: "#01A26205",
    border: "#cce8dd",
    border100: "#01A26214",
    // border: "#01A26214",
    main: {
      50: "hsl(156, 99%, 10%)",
      100: "hsl(156, 99%, 14%)",
      200: "hsl(156, 99%, 18%)",
      300: "hsl(156, 99%, 22%)",
      400: "hsl(156, 99%, 28%)",
      500: "#01A262",
      600: "hsl(156, 99%, 42%)",
      700: "hsl(156, 99%, 52%)",
      800: "hsl(156, 99%, 62%)",
      900: "#018C55",
    },
  },
  child: {
    primary: "#111",
    text: "#000",
    text100: "#111",
    bg: "#fff",
    bg100: "#fff",
    border: "#111",
    border100: "#11111180",
    main: {
      50: "hsl(156, 99%, 0%)",
      100: "hsl(156, 99%, 1%)",
      200: "hsl(156, 99%, 2%)",
      300: "hsl(156, 99%, 3%)",
      400: "hsl(156, 99%, 4%)",
      500: "#111",
      600: "hsl(156, 99%, 66%)",
      700: "hsl(156, 99%, 77%)",
      800: "hsl(156, 99%, 88%)",
      900: "hsl(156, 99%, 98%)",
    },
  },
};

export const ROUTES = {
  landing: "/",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  register: "/register",
  onboarding: "/onboarding",

  dashboard: "/dashboard",
  performance: "/performance",
  children: "/children",
  childProfile: (param?: string) => {
    if (param) {
      return `/children/child/${param}`;
    }
    return "/children/child/:uuid";
  },
  subscription: "/subscription",
  account: "/account",
};

export const ORGANIZATION_TYPES = [
  { label: "Church", value: "church" },
  { label: "Society", value: "society" },
  { label: "Playground", value: "playground" },
  { label: "Mosque", value: "mosque" },
  { label: "School", value: "school" },
];

export const ORGANIZATION_KIDS = [
  { label: "10 - 99", value: "10 - 99" },
  { label: "100 - 350", value: "100 - 350" },
  { label: "351 - 500", value: "351 - 500" },
  { label: "501 - 1000", value: "501 - 1000" },
  { label: "1000 - 5000", value: "1000 - 5000" },
];
