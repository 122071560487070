import { ROUTES } from "@/utils/constants";
import { lazy } from "react";

const OnboardingPage = lazy(() => import("../../pages/auth/onboarding"));
const DashboardPage = lazy(() => import("../../pages/dashboard"));
const ChildrenPage = lazy(() => import("../../pages/children"));
const ChildProfilePage = lazy(
  () => import("../../pages/children/child/profile")
);
const SubscriptionPage = lazy(() => import("../../pages/subscription"));
const AccountPage = lazy(() => import("../../pages/account"));
const LeaderBoardPage = lazy(() => import("../../pages/leader-board"))

export const privateRoutes = [
  {
    name: "OnboardingPage",
    path: ROUTES.onboarding,
    component: OnboardingPage,
  },
  {
    name: "DashboardPage",
    path: ROUTES.dashboard,
    component: DashboardPage,
  },
  {
    name: "LeaderBoardPage",
    path: ROUTES.performance,
    component: LeaderBoardPage,
  },
  {
    name: "ChildrenPage",
    path: ROUTES.children,
    component: ChildrenPage,
  },
  {
    name: "Child Profile Page",
    path: ROUTES.childProfile(),
    component: ChildProfilePage,
  },
  {
    name: "Subscription",
    path: ROUTES.subscription,
    component: SubscriptionPage,
  },
  {
    name: "AccountPage",
    path: ROUTES.account,
    component: AccountPage,
  },
];
